<template>
    <div id="shareURL">
        <button v-if="isMobile" @click="showPopup" class="icon-button white-button">
            <img src="img/icons/share_button.svg" />
        </button>
        <button v-else :class="['button']" @click="showPopup">{{ $t('button.share') }}</button>
    </div>
</template>

<script>
export default {
    props: {},
    name: 'ShareURL',
    data() {
        return {
            // showPopup: false,
            url: window.location.href,
            windowWidth: window.innerWidth
        }
    },
    computed: {
        isMobile() {
            if (this.windowWidth <= 767) {
                return true
            } else return false
        }
    },
    methods: {
        async copyURL() {
            // var dummy = document.createElement('input'),
            //     text = window.location.href
            // document.body.appendChild(dummy)
            // dummy.value = text
            // dummy.select()
            // document.execCommand('copy')
            // document.body.removeChild(dummy)

            try {
                let text = window.location.href
                await navigator.clipboard.writeText(text)

                this.$snackbar.close()

                this.$snackbar.open({
                    message: this.$t('snackbar.copy_share_link'),
                    color: 'success',
                    position: 'top',
                    duration: 2500
                })
            } catch (error) {
                console.error('Error al copiar el link: ', error)
            }
        },
        showPopup() {
            const self = this

            const popupData = {
                title: this.$t('share.title'),
                message: this.$t('share.label'),
                textSave: this.$t('user.form.copy_link'),
                config: {
                    confirmType: 'share-url'
                    // url: self.url
                },
                callSave: () => {
                    self.copyURL()
                },
                callCancel: () => {
                    self.$popup.close()
                }
            }

            self.$popup.confirm(popupData)
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        }
    },
    watch: {},
    created() {
        // for mobile
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style lang="scss">
#shareURL {
    .button {
        @include display-inline-flex();
        @include border-radius(4px);
        @include background($image: img('share.svg'), $color: $color-neutral-200, $size: 18px, $position: 11px center);
        @include box-shadow($h: 0px, $v: 0px, $b: 4px, $s: 0px, $c: rgba($color-neutral-600, 0.05));
        cursor: pointer;
        padding: 8px 9px 8px 35px;
        margin: 0 0 0 12px;
        height: auto;
        width: max-content;
        max-width: unset;
        font-family: $text-bold;
        color: $color-black;
        line-height: initial;
        background-color: $color-white;
        border: 1px solid $color-neutral-300;

        &.no-icon {
            background-image: none;
            padding: 8px 9px;

            b {
                margin-right: 5px;
            }
        }

        &:hover {
            background-color: $color-neutral-300;
        }

        &.edit {
            @include background($image: img('edit_neutro_s90.svg'), $color: $color-neutral-200, $size: 15px, $position: 11px center);
        }
    }

    .popup {
        @include display-flex();
        @include align-items(flex-start);
        background-color: #fff;
        position: absolute;
        top: 55px;
        right: 150px;
        width: max-content;
        max-width: unset;
        height: auto;
        max-height: unset;
        padding: 20px;
        overflow: visible;
        @include box-shadow($h: 0px, $v: 2px, $b: 15px, $s: 2px, $c: rgba($color-neutral-200, 1));

        .label {
            @include font-size(md);
            font-family: $text;
            color: $color-black;
            margin: 0 0 10px 0;
            width: 100%;
        }

        .content {
            @include display-flex();
            @include align-items(center);
            width: 100%;

            .url {
                @include font-size(sm);
                @include border-radius(4px);
                background-color: $color-neutral-200 !important;
                color: $color-black !important;
                padding: 8px 9px;
                height: auto;
                font-family: $text-light;
                line-height: initial;
                cursor: pointer;
                width: calc(100% - 150px);
                display: inline-block;
            }

            .copy {
                display: inline-block;
                @include font-size(md);
                @include justify-content(center);
                color: $color-primary-800;
                height: auto;
                font-family: $text-bold;
                line-height: initial;
                cursor: pointer;
                width: 150px;
                text-align: center;

                // &:hover {
                // }
            }
        }
    }
}
</style>
