<template>
    <div v-if="user" class="user-tag" :class="[size, styleOf]">
        <span v-if="user && hasAvatar" class="avatar">
            <img class="main-img" v-bind:src="user.avatar" />
        </span>
        <span v-if="user" class="name" v-bind:style="{ color: user.color }">{{ user.name + ' ' + user.surname }},</span>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        userId: { type: String },
        size: { type: String, default: 'large' }, // small, medium, large
        styleOf: { type: String, default: 'block' }, // block, inline
        hasAvatar: { type: Boolean, default: true } // block, inline
    },
    data() {
        return {}
    },
    computed: {
        user() {
            let result = this.$store.getters['employee/getAccountEmployee'](this.userId)
            // console.log('user', result)
            return result || false
        }
    },
    methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.user-tag {
    .name {
        $color-black: #222222;
        display: flex;
        align-items: center;
    }

    &.block {
        $user-height: 40px;
        $user-width: calc(100% - 60px);
        $avatar-size: $user-height - 10px;

        @include display-inline-flex();
        @include align-items();
        @include justify-content();
        width: 100%;
        max-height: $user-height;
        text-align: center;
        // cursor: pointer;

        .avatar {
            @include border-radius($avatar-size);
            width: $avatar-size;
            height: $avatar-size;

            &:after {
                @include text-ellipsis();
                @include font-size(xs);
                line-height: $avatar-size;
                font-family: $text-bold;
                color: #fff;
                text-align: center;
                width: 100%;
                height: 100%;
                display: inline-block;
            }
        }
        .name,
        .empty {
            @include text-ellipsis();
            @include font-size(sm);
            font-family: $text-bold;
            width: calc(100% - #{$avatar-size});
            padding-left: 6px;
        }
        // .empty {
        //     // TODO
        // }

        &.small {
            $user-height: 10px;
            $user-width: calc(100% - 40px);
            $avatar-size: $user-height;

            width: 100%;
            max-height: $user-height;

            .avatar {
                @include border-radius($avatar-size);
                width: $avatar-size;
                height: $avatar-size;

                &:after {
                    content: '';
                    line-height: $avatar-size;
                }
            }
            .name,
            .empty {
                width: calc(100% - #{$avatar-size});
                padding-left: 3px;
                // color: #444 !important;
                font-family: $text-light;

                @media (max-width: 767px) {
                    @include font-size(xs);
                }
            }
        }
        &.medium {
            $user-height: 22px;
            $user-width: calc(100% - 40px);
            $avatar-size: $user-height;

            width: 100%;
            max-height: $user-height;

            .avatar {
                @include border-radius($avatar-size);
                width: $avatar-size;
                height: $avatar-size;

                &:after {
                    @include font-size(xs);
                    font-family: $text-bold;
                    line-height: $avatar-size;
                    width: 100%;
                    height: 100%;
                }
            }
            .name,
            .empty {
                @include font-size(16);
                width: calc(100% - #{$avatar-size});
                padding-left: 6px;
                color: #666 !important;
                font-family: $text;
            }
        }
        &.large {
            $user-height: 40px;
            $user-width: calc(100% - 60px);
            $avatar-size: $user-height;

            width: 100%;
            max-height: $user-height;

            .avatar {
                @include border-radius($avatar-size);
                width: $avatar-size;
                height: $avatar-size;

                &:after {
                    @include font-size(xs);
                    font-family: $text-bold;
                    line-height: $avatar-size;
                    width: 100%;
                    height: 100%;
                }
            }
            .name,
            .empty {
                @include font-size(sm);
                width: calc(100% - #{$avatar-size});
                padding-left: 6px;
                color: #666 !important;
                font-family: $text;
            }
        }
    }

    // &.inline {
    //     // TODO
    // }

    @media (min-width: 768px) {
        height: 29px !important;
        max-height: 29px !important;
        display: flex !important;
        align-items: center !important;
    }
}
</style>
